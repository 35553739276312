<script setup lang="ts">
import 'swiper/css'
import { Swiper, SwiperSlide } from 'swiper/vue'
import type { SliceInterface } from '@voix/types'

const props = defineProps({
  slice: {
    type: Object as PropType<SliceInterface>,
    required: true,
  },
})
defineSlice({
  deprecated: true,
  name: { label: 'Brands Section', group: 'blocks' },
  preview: 'SlicesBlocksBrandsSection.jpg',
  slots: [{ name: 'default', allowedElements: [] }],
})
</script>

<template>
  <div id="brands" class="relative">
    <div>
      <div class="absolute z-0 inset-0 hidden xl:block">
        <div class="bg-nonPhotoBlue w-full h-full" />
      </div>
      <div class="relative z-10 py-16 container">
        <div class="absolute z-0 inset-0 xl:hidden">
          <div class="bg-nonPhotoBlue w-full h-full" />
        </div>
        <div class="relative z-10 grid grid-cols-1 lg:grid-cols-3 gap-8">
          <div v-for="(slice) in props.slice.elements" :key="slice.id" class="flex">
            <VoixSlice :slice="slice as SliceInterface" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
section {
  @apply py-12 h-max xl:py-16;

  .brands-wrapper {
    @apply pt-5 pb-8 relative;

    .x-container {
      @apply flex flex-col xl:flex-row;
    }

    h2,
    ul li {
      @apply text-5xl font-thin;
    }

    h2 {
      @apply tracking-widest text-center block xl:hidden;

      @screen max-md {
        @apply text-4xl;
      }

      @screen xxs {
        @apply text-3xl;
      }
    }

    ul[aria-label] {
      @apply hidden xl:flex flex-col justify-end items-center;

      li {
        @apply transform rotate-[-90deg];

        &:not(.space) {
          @apply mb-[-14px];
        }

        &.space {
          @apply h-5;
        }
      }
    }

    .cards-wrapper {
      @apply w-full h-full flex-1;

      .cards {
        @apply flex gap-4 items-center w-full overflow-x-scroll;

        @screen md {
          @apply grid grid-cols-2 gap-2;
        }

        @screen lg {
          @apply flex gap-4;
        }
      }
    }

    .swiper-slide {
      @apply h-auto flex;
    }

  }
}
</style>
